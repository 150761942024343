
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import moment from "moment";

// interfaces
import { PortalUserVM, SelectListItem, GroupVm, OrganizationVm } from "@/interfaces/PortalUserVM";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";

// components
import SetAvatar from "@/components/SetAvatar.vue";
import { UserTagVm } from "@/interfaces/UserTagVm";
import { List } from "linq-collections";
import { CustomAttributeName } from "@/interfaces/CustomAttributeName";

const globals = namespace("globals");

@Component({
  components: {
    SetAvatar,
  }
})
export default class EditUser extends Vue {
  @Prop() user!: PortalUserVM;
  @Prop() isNewUser!: boolean;
  @Prop() showProfile!: boolean;
  @Prop() saveUserUrl!: string;
  @PropSync("showDialog") syncedShowDialog!: boolean;

  busy = false;
  formValid = true;
  // birthday dialog
  showBirthdayDlg = false;
  birthdayAsIso: string | null = null;
  birthdayAsText: string | null = null;
  //entry date dialog
  showEntryDateDlg = false;
  entryDateAsIso: string | null = null;
  entryDateAsText: string | null = null;

  userTags: UserTagVm[] = [];
  tagSearch: string | null = null;
  customAttributeNames: CustomAttributeName[] = [];
  isLoading = false;
  isAllOrgsLoading = false;
  allOrgs = false;
  newUser: PortalUserVM | null = null;
  editUser: PortalUserVM | null = null;

  $refs!: {
    editUserForm: HTMLFormElement;
    firstnameTextField: HTMLElement;
    tagSelectBox: HTMLElement;
  };

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      if (!this.showProfile) {
        this.loadUserTags();
        this.loadCustomAttributeNames();
      }

      this.allOrgs = false;
      this.birthdayAsIso = null;
      this.birthdayAsText = null;
      this.entryDateAsIso = null;
      this.entryDateAsText = null;
      if (this.isNewUser) {
        this.editUser = null;
        this.newUser = null;
        await this.$globalHelper.delay(10);
        this.newUser = new PortalUserVmClass();
        this.newUser.entryDate = new Date(new Date().toDateString());
        this.editUser = this.newUser;
        this.entryDateAsIso = moment(this.editUser.entryDate).format("yyyy-MM-DD");
        this.entryDateAsText = moment(this.entryDateAsIso).format("DD.MM.yyyy");
        // this.createNewKey();
        await this.$globalHelper.delay(10);
        this.$refs.firstnameTextField.focus();
      } else {
        this.editUser = _.cloneDeep(this.user);
        this.entryDateAsText = moment(this.editUser.entryDate).format("DD.MM.yyyy");
        if (this.editUser.birthday) {
          this.birthdayAsIso = moment(this.editUser.birthday).format("yyyy-MM-DD");
          this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
        }
        if (this.editUser.entryDate) {
          this.entryDateAsIso = moment(this.editUser.entryDate).format("yyyy-MM-DD");
          this.entryDateAsText = moment(this.entryDateAsIso).format("DD.MM.yyyy");
        }
        // this.$refs.editUserForm.validate();
      }

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      this.editUser = new PortalUserVmClass();
      clearAllBodyScrollLocks();
    }
  }

  @Watch("editUser.userTags")
  onTagsChanged(val: (UserTagVm | string)[], prev) {
    if (!this.editUser) return;

    // console.log("val", val);
    // console.log("prev", prev);
    if (val?.length === prev?.length) return;

    const existTags = new List(val).where(t => typeof t !== "string").select(t => t as UserTagVm);
    const newTags = new List(val).where(t => typeof t === "string").select(t => <UserTagVm>{ id: 0, name: t});
    const allTags = existTags.concat(newTags);
    const tagsWithoutId = allTags.where(t => t.id == 0);
    const newTagNames = tagsWithoutId.select(t => t.name?.toUpperCase());
    const tagsWithMissingId = new List(this.userTags).where(t => newTagNames.contains(t.name?.toUpperCase()));
    const tagNames = tagsWithMissingId.select(t => t.name?.toUpperCase());
    const validTags = allTags.where(t => !tagNames.contains(t.name?.toUpperCase()));
    this.editUser.userTags = validTags.concat(tagsWithMissingId).toArray();
    // Update tag select list
    const tagListNames = new List(this.userTags).select(t => t.name?.toUpperCase());
    const tagsToAdd = new List(this.editUser.userTags).where(t => !tagListNames.contains(t.name?.toUpperCase()));
    this.userTags = new List(this.userTags).concat(tagsToAdd).toArray();
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async loadUserTags() {
    await rest.url("userManagement/loadUserTags")
      .get()
      .then((response) => {
        this.userTags = response;
      })
  }

  async loadCustomAttributeNames() {
    await rest.url("groupAdmin/loadCustomAttributeNames")
      .get()
      .then((response) => {
        this.customAttributeNames = response;
      })
  }

  // async loadUserList() {
  //   await rest.url("groupAdmin/getOrgUsers")
  //     .query({ groupId: this.selectedGroup?.id ?? 0, orgId: this.selectedOrg?.id ?? 0, allOrgs: this.allOrgs, searchValue: this.searchValue ?? "" })
  //     .get()
  //     .then((response) => {
  //       this.userList = response;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  // }

  // async onNewUserSelected() {
  //   // console.log("onNewUserSelected");
  //   if (!this.isNewUser) return;
  //   this.editUser = null;
  //   await this.$globalHelper.delay(10);
  //   this.editUser = this.newUser!;
  //   // if (this.editUser.birthday!.getTime() != this.defaultUser.birthday!.getTime()) {
  //   if (this.editUser.birthday!.getTime() != new PortalUserVmClass().birthday!.getTime()) {
  //     this.birthdayAsIso = moment(this.editUser.birthday).format("yyyy-MM-DD");
  //     this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
  //   } else {
  //     this.birthdayAsIso = null;
  //     this.birthdayAsText = null;
  //   }
  // }

  // onSearchUserSelected() {
  //   if (!this.isNewUser) return;
  //   this.editUser = null;
  // }

  // async onUserSelected() {
  //   if (!this.isNewUser) return;
  //   if (!this.editUser)  return;

  //   // this.editUser.groupId = this.selectedGroup?.id ?? 0;
  //   // this.editUser.organizationId = this.selectedOrg?.id ?? 0;
  //   if (this.editUser.birthday) {
  //     this.birthdayAsIso = moment(this.editUser.birthday).format("yyyy-MM-DD");
  //     this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
  //   } else {
  //     // this.birthdayAsIso = null;
  //     this.birthdayAsText = null;
  //   }

  //   await this.$globalHelper.delay(10);
  //   this.$refs.editUserForm.validate();
  // }

  // addNewUser() {
  //   this.foundUser = null;
  //   this.editUser = new PortalUserVmClass();
  //   this.editUser.groupId = this.selectedGroup.id;
  //   this.isNewUser = true;
  // }

  createNewKey() {
    if (!this.editUser) return;
    if (this.birthdayAsIso) {
      this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
      vuex.ux.SB_SUCCESS({
        message: "Geburtsdatum als Aktivierungsschlüssel gesetzt.",
        timeout: 3000
      });
      return;
    }

    this.editUser.activationKey = "";
    for (let i = 0; i <= 7; i++) {
      this.editUser.activationKey += Math.floor(Math.random() * 10);
    }
  }

  onBirthdayTextChanged(): void {
    if (!this.editUser) return;
    // if (!this.birthdayAsText) return;
    if (!this.birthdayAsText || !this.isValidDate(this.birthdayAsText)) {
      this.birthdayAsText = null;
      this.birthdayAsIso = null;
      return;
    }

    let isoDate = this.convertDeDateToIso(this.birthdayAsText)
    // Check if valid date
    if (!moment(isoDate).isValid()) {
      this.birthdayAsText = null;
      this.birthdayAsIso = null;
      return;
    }
    this.birthdayAsIso = isoDate;
    if (this.isNewUser)
      this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
  }

  onBirthdayPicker() {
    if (!this.editUser) return;
    if (!this.birthdayAsIso) {
      this.birthdayAsText = null;
      this.showBirthdayDlg = false;
      return;
    }

    this.birthdayAsText = moment(this.birthdayAsIso).format("DD.MM.yyyy");
    this.showBirthdayDlg = false;
    if (this.isNewUser)
      this.editUser.activationKey = moment(this.birthdayAsIso).format("DDMMyyyy");
  }

  get birthdayFormated() {
    return this.formatDate(this.birthdayAsIso);
  }

  onEntryDateTextChanged(): void {
    if (!this.editUser) return;
    if (!this.entryDateAsText) return;
    if (!this.isValidDate(this.entryDateAsText)) {
      // this.entryDateAsIso = null; // moment(new Date()).format("yyyy-MM-DD");
      // this.entryDateAsText = null; // moment(new Date()).format("DD.MM.yyyy");
      return;
    }

    let isoDate = this.convertDeDateToIso(this.entryDateAsText)
    // Check if valid date
    if (!moment(isoDate).isValid()) {
      this.entryDateAsText = null;
      this.entryDateAsIso = null;
      return;
    }
    this.entryDateAsIso = isoDate;
  }

  onEntryDatePicker() {
    if (!this.editUser) return;
    if (!this.entryDateAsIso) {
      this.entryDateAsText = null;
      this.showEntryDateDlg = false;
      return;
    }

    this.entryDateAsText = moment(this.entryDateAsIso).format("DD.MM.yyyy");
    this.showEntryDateDlg = false;
  }

  get entryDateFormated() {
    return this.formatDate(this.entryDateAsIso);
  }

  isValidDate(val: string | null): boolean {
    if (!val)
      return true;
    let dateSplit = val.split('.');
    if (dateSplit?.length != 3)
      return false;
    if (dateSplit[2].length < 4)
      return false;
    if (dateSplit[1].length < 1 || dateSplit[1].length > 2)
      return false;
    if (dateSplit[0].length < 1 || dateSplit[0].length > 2)
      return false;

    return moment(this.convertDeDateToIso(val)).isValid();
  }

  convertDeDateToIso(val: string): string {
    // console.log("val", val);
    // console.log("this.birthdayAsText", this.birthdayAsText);
    // let dateSplit = this.birthdayAsText?.split('.');
    let dateSplit = val.split('.');
    let isoDate = dateSplit![2] + "-";
    if (dateSplit![1].length < 2)
      isoDate += "0";
    isoDate += dateSplit![1] + "-";
    if (dateSplit![0].length < 2)
      isoDate += "0";
    isoDate += dateSplit![0];
    return isoDate;
  }

  formatDate(date) {
    if (date == null)
      return "-";

    return moment(date).format("DD.MM.yyyy");
  }

  onAvatarChanged(avatar: string) {
    if (!this.editUser) return;
    this.editUser.avatar = avatar;
  }

  get dialogTitle() {
    if (this.showProfile)
      return "Mein Profil";

    if (!this.isNewUser)
      return  "Anwender bearbeiten";

    if (this.$vuetify.breakpoint.xs)
      return "Anwender anlegen";

    return "Neuen Anwender anlegen";
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }

  async saveUser() {
    if (!this.editUser) return;
    if (!this.$refs.editUserForm.validate()) return;
    if (this.busy) return;

    this.busy = true;
    this.editUser.userName = this.editUser.userName?.trim() ?? null;
    this.editUser.firstName = this.editUser.firstName?.trim() ?? null;
    this.editUser.lastName = this.editUser.lastName?.trim() ?? null;
    this.editUser.nickname = this.editUser.nickname?.trim() ?? null;
    this.editUser.email = this.editUser.email?.trim() ?? null;
    this.editUser.corporateId = this.editUser.corporateId?.trim() ?? null;
    this.editUser.birthday = this.birthdayAsIso ? new Date(this.birthdayAsIso) : null;
    // this.editUser.birthday = this.birthdayAsIso ? moment.utc(this.birthdayAsIso).toDate() : null;
    this.editUser.entryDate = new Date(this.entryDateAsIso!);

    await rest.url(this.saveUserUrl)
    .post(this.editUser)
    .then((response) => {
      console.log("userSaved:EditUser");
      this.$emit('userSaved:EditUser', true);
      this.closeDialog();
    })
    .finally(() => {
      this.busy = false;
    });
  }

  async copyToClipboard(value: string) {
    await navigator.clipboard.writeText(value);
     this.$store.commit("ux/SB_SUCCESS", {
        message: value + " in Zwischenablage kopiert",
        timeout: 2000
      });
  }

  nullableDateRules = [
    (v: string) => this.isValidDate(v) || "falsches Datumsformat"
  ];

  dateRules = [
    (v: string) => !!v || "Datum erforderlich",
    // (v: string) => (v && this.isValidDate(v)) || "falsches Datumsformat"
    (v: string) => this.isValidDate(v) || "falsches Datumsformat"
  ];

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername zu kurz."
  ];

  // keyRules = [
  //   //(v: string) => !!v || "Schlüssel erforderlich",
  //   (v: string) => v.length == 0 || v.length > 7 || "Schlüssel zu kurz."
  // ];
  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
  ];

  keyEditRules = [
    (v: string) => !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz"
  ];

  phoneNumberRules = [
    (v: string) => (!v || (v && v.length <= 50)) || "Telefonnummer zu lang"
  ];

  departmentRules = [
    (v: string) => (!v || (v && v.length <= 120)) || "Abteilung zu lang"
  ];

  locationRules = [
    (v: string) => (!v || (v && v.length <= 150)) || "Standort zu lang"
  ];

  organisationRules = [
    (v: string) => (!v || (v && v.length <= 150)) || "Organisation zu lang"
  ];

  jobTitleRules = [
    (v: string) => (!v || (v && v.length <= 200)) || "Jobbezeichnung zu lang"
  ];

  costCenterRules = [
    (v: string) => (!v || (v && v.length <= 50)) || "Kostenstelle zu lang"
  ];

  customAttributeRules = [
    (v: string) => (!v || (v && v.length <= 80)) || "Wert zu lang"
  ];

  corporateIdRules = [
    (v: string) => (!v || (v && v.length <= 50)) || "Personalnummer zu lang"
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
  ];
}
